// IMPORTS
import * as React from "react";

// local
import "../assets/App.css";

// Material UI components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";

// Material UI icons
import BusinessIcon from "@mui/icons-material/Business";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

// inline styles
const cardStyle = {
  width: "100%",
  bgcolor: "#DDD7D0",
};

const buttonStyle = {
  color: "#2e5b5b",
  marginBottom: "-3px",
  textAlign: "center",
};

// PROPS
const email = {
  sectionTitle: "Email",
  subtitle: "thetajsalon@gmail.com",
  action: "Click to Copy Email",
};

const phone = {
  sectionTitle: "Phone",
  subtitle: "(352) 268-2049",
  action: "Click to Call",
};

const address = {
  sectionTitle: "Our Location:",
  embedSrc: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3492.4502297565405!2d-81.92403228813231!3d28.9146808754097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e7c7c34b994aa1%3A0xb336beb18a4cec!2sThe%20TAJ%20Salon%20llc!5e0!3m2!1sen!2sus!4v1704322851004!5m2!1sen!2sus"`,
  addressFull: "130 US Highway 441 Lady Lake, FL 32159",
  addressLine1: "130 US Highway 441",
  addressLine2: "Lady Lake, FL 32159",
  action: "Click to Copy Address",
};

export const ContactModal = () => {
  //Hooks
  const [copy, setCopy] = React.useState(false);
  const [openSnackCopied, setOpenSnackCopied] = React.useState(false);

  //Functions
  const handleCopyAddress = () => {
    setCopy(true);
    navigator.clipboard.writeText(`${address.addressFull}`);
    handleSnackCopied();
  };

  const handleCopyEmail = () => {
    setCopy(true);
    navigator.clipboard.writeText(`${email.subtitle}`);
    handleSnackCopied();
  };

  const handleSnackCopied = () => {
    setOpenSnackCopied(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      setOpenSnackCopied(false);
    }
    setOpenSnackCopied(false);
  };

  return (
    <>
      {/* open container */}
      {/* <div className="contact-container"> */}
      <div className="contact-container location-card">
        <Card sx={cardStyle}>
          <CardMedia
            className="iframe"
            component="iframe"
            src={address.embedSrc}
            alt="The TAJ Salon"
          />
          <CardContent>
            <div className="contact-store-info">
              <h3>The TAJ Salon</h3>
              <p style={{ marginBottom: "2px" }}>Monday | Closed</p>
              <p>Tuesday-Sunday | 9:00 A.M. - 5:00 P.M.</p>
            </div>
            <Divider />
            <Button
              className="contact-button"
              onClick={handleCopyAddress}
              variant="none"
              size="small"
              color="success"
              fullWidth
            >
              <p style={buttonStyle}>
                <BusinessIcon />
                <br />
                {address.addressLine1}
                <br />
                {address.addressLine2}
                <br />({address.action})
              </p>
            </Button>
            <Divider />
            <Button
              className="contact-button"
              href={`tel:${phone.subtitle}`}
              label={`${phone.subtitle}`}
              variant="none"
              size="large"
              color="success"
              fullWidth
            >
              <p style={buttonStyle}>
                <PhoneIcon />
                <br />
                {phone.subtitle}
                <br /> ({phone.action})
              </p>
            </Button>
            <Divider />
            <Button
              className="contact-button"
              onClick={handleCopyEmail}
              variant="none"
              size="small"
              color="success"
              fullWidth
            >
              <p style={buttonStyle}>
                <EmailIcon />
                <br />
                {email.subtitle}
                <br />({email.action})
              </p>
            </Button>
          </CardContent>
        </Card>
        <Snackbar
          open={openSnackCopied}
          autoHideDuration={1200}
          onClose={handleCloseSnack}
        >
          <Alert severity="success" color="success">
            Copied to clipboard
          </Alert>
        </Snackbar>
      </div>
      {/* </div> */}
      {/* close container */}
    </>
  );
};

export default ContactModal;

// Email link: <Button href={`mailto:${email}`} label={`${email}`} startIcon={<EmailIcon />}>Email</Button>
// Phone link: <Button href={`tel:${phone}`} label={`${phone}`} startIcon={<PhoneIcon />}>Phone</Button>
