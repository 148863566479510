// IMPORTS

// general
import * as React from "react";

// local
import "../assets/App.css";
import "../assets/imageSource.css";

// PROPS

export const Header = () => {
  // styles
  const spanStyle = {
    color: "black",
    textShadow: "0px 1px 4px white",
    fontWeight: "bolder",
  };

  const pStyle = {
    color: "black",
    textShadow: "-0.1px 0 white, 0 0.1px white, 0.1px 0 white, 0 -0.1px white",
  };

  //Hooks

  //Functions

  return (
    <>
      <div className="header header-img">
        <div className="header-background">
          <div className="header-text background-blur">
            <h1 className="header-title">
              The <span style={spanStyle}>TAJ</span> Salon
            </h1>
            {/* <div style={h1RemoveMargin}></div> */}
            <p style={pStyle}>Est. 2021</p>
            <p style={pStyle}>130 US Highway 441 Lady Lake, FL 32159</p>
            <p style={pStyle}>(352) 268-2049</p>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default Header;

// PSEUDO
// PSEUDO
// PSEUDO

// top bar (under nav)
// address
// phone
