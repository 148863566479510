//IMPORTS
import * as React from "react";
import { useEffect } from "react";

// local
import "../assets/App.css";
import "../assets/imageSource.css";

// Material UI Components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// animations
import Aos from "aos";
import "aos/dist/aos.css";
import SectionDivider from "../components/SectionDivider";

const cardStyle = {
  m: "auto",
  maxWidth: "auto",
  color: "#1F271B",
  bgcolor: "#CBC4B9",
  borderRadius: "3% 5% 10% 20%",
  border: 3,
  borderColor: "white",
  textAlign: "center",
};

const h1ContrastColor = {
  color: "#ddede2",
  textAlign: "center",
};

export const About = () => {
  //Hooks
  useEffect(() => {
    Aos.init({
      once: true,
    });
  }, []);

  return (
    <>
      <SectionDivider />
      <h1 className="page-title">About Us</h1>
      <hr />

      <div>
        <Card sx={cardStyle}>
          <div className="about-center taj-store-first">
            <h2
              style={h1ContrastColor}
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="700"
            >
              The TAJ Salon is a quaint establishment tucked in the middle of
              central Florida, comfortably near Leesburg, the Villages, and
              Ocala.
            </h2>
          </div>
          <CardContent>
            <div className="about-services">
              <h2
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="700"
              >
                We offer a variety of services:
              </h2>
              <ul>
                <li
                  data-aos="fade-up"
                  data-aos-delay="200"
                  data-aos-duration="700"
                >
                  Hair Extension Methods
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="700"
                >
                  Custom Color Services
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="700"
                >
                  Smoothing Treatments
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-delay="500"
                  data-aos-duration="700"
                >
                  Haircutting and Hairstyling
                </li>
              </ul>
            </div>
          </CardContent>
        </Card>
        <br />
        <br />
        <br />
        <div>
          <Card sx={cardStyle}>
            <div className="about-center taj-store-second">
              <h1
                style={h1ContrastColor}
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="700"
              >
                What does "TAJ" mean?
              </h1>
            </div>
            <CardContent>
              <h5
                className="about-card"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="700"
              >
                (People wonder how we came up with the name for the salon.)
              </h5>
            </CardContent>
          </Card>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1
          style={h1ContrastColor}
          data-aos="fade-up"
          data-aos-delay="350"
          data-aos-duration="700"
        >
          "TAJ" is an acronym for “Tesla and Juli.“
        </h1>
        <br />
        <br />
        <br />
        <br />
        <div>
          <div className="about-split">
            <br />
            <br />
            <br />
            <h3 data-aos="fade-up" data-aos-delay="500" data-aos-duration="700">
              Creating the salon and naming it “TAJ” was a way for Tesla to pay
              homage to the late Juli Roberts.
            </h3>
            <br />
            <h3 data-aos="fade-up" data-aos-delay="600" data-aos-duration="700">
              Juli owned the VIP Hair Salon, where she and Tesla worked
              side-by-side and created a lifelong bond.
            </h3>
            <br />
            <h3 data-aos="fade-up" data-aos-delay="700" data-aos-duration="700">
              Not only was Juli an amazing hairstylist; she was a great mentor
              and beloved friend.
            </h3>
            <br />
            <div
              className="about-split homage-to-juli"
              data-aos="fade-up"
              data-aos-delay="800"
              data-aos-duration="800"
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
