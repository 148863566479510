// IMPORTS
import * as React from "react";

// local
import "../assets/App.css";

// Material UI components
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Link from "@mui/material/Link";

// Material UI icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material UI Functions

// LINKS
const facebookLink = "https://www.facebook.com/p/HAIRbyTheTAJsalon";
const instagramLink = "https://www.instagram.com/thetaj352";

export const Footer = () => {
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10, // added to maintain in foreground
        }}
      >
        <>
          <BottomNavigation showLabels>
            <BottomNavigationAction
              component={Link}
              href={instagramLink}
              target="_blank"
              label="Instagram"
              icon={<InstagramIcon />}
            />
            <BottomNavigationAction
              component={Link}
              href={facebookLink}
              target="_blank"
              label="Facebook"
              icon={<FacebookIcon />}
            />
          </BottomNavigation>
        </>
      </Box>
    </>
  );
};

export default Footer;
