//Imports
import * as React from "react";
// import { useEffect } from "react"; // USED TO TROUBLSHOOT CAPTION BUG; DELETE IN CODE CLEANUP
// import { useState } from "react"; // USED TO TROUBLSHOOT CAPTION BUG; DELETE IN CODE CLEANUP

// local styles
import "../assets/App.css";
import "../assets/imageSource.css";

// React/MUI Component
import Carousel from "react-material-ui-carousel";

// inline styles
// const carouselImgStyle = {
//   width: "100%",
//   height: "auto",
//   // boxSizing: "border-box",
//   objectFit: "cover",
//   objectPosition: "center top",
// };

const addShadow = {
  textShadow: "0px 1px 4px black",
};

export const ServiceSubCarousel = (props) => {
  // Hooks

  // // USED TO TROUBLSHOOT CAPTION BUG; DELETE IN CODE CLEANUP (START)
  // // USED TO TROUBLSHOOT CAPTION BUG; DELETE IN CODE CLEANUP (START)
  // // vvv

  // const [showCaption, setShowCaption] = useState(false);
  // const [firstImageLoaded, setFirstImageLoaded] = useState(false);

  // useEffect(() => {
  //   // If the first image has loaded, show the caption
  //   if (firstImageLoaded) {
  //     console.log("first image has loaded!");
  //     setShowCaption(true);
  //   }
  // }, [firstImageLoaded]); // Dependency on the first image's load state

  // const handleFirstImageLoad = () => {
  //   if (!firstImageLoaded) {
  //     // Ensure this runs only once
  //     setFirstImageLoaded(true);
  //   }
  // };

  // // ^^^
  // // USED TO TROUBLSHOOT CAPTION BUG; DELETE IN CODE CLEANUP (END)
  // // USED TO TROUBLSHOOT CAPTION BUG; DELETE IN CODE CLEANUP (END)

  return (
    <>
      <Carousel
        className="sub-carousel-styled"
        navButtonsAlwaysVisible
        duration={800}
        indicatorContainerProps={{
          style: {
            backgroundColor: "#00000039",
            zIndex: 1,
            position: "relative",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: { color: "white", zIndex: 1 },
        }}
        indicatorIconButtonProps={{
          style: {
            color: "#80ad8e",
            zIndex: 1,
          },
        }}
      >
        {props.image.map((photo, index) => (
          <div className="sub-carousel-img-container" key={index}>
            <img
              alt={props.kind[index]}
              src={photo}
              // onLoad={index === 0 ? handleFirstImageLoad : null} // USED TO TROUBLSHOOT CAPTION BUG; DELETE IN CODE CLEANUP
              // style={carouselImgStyle}
            />
            {/* {showCaption && ( */}
            <div className="sub-carousel-caption background-blur">
              <h3 style={addShadow}>{props.kind[index]}</h3>
            </div>
            {/* )} */}
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default ServiceSubCarousel;
