// IMPORTS
import * as React from "react";
// import { useEffect } from "react";

// local styles
import "../assets/App.css";
import "../assets/imageSource.css";

// MUI icons
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

// inline styles
const arrowStyle = {
  color: "white",
  display: "flex",
  m: "auto",
};

export const SectionDivider = () => {
  //Hooks

  // Functions

  return (
    <>
      <ArrowDownwardIcon fontSize="large" sx={arrowStyle} />
      <div id="pageTitle" />
      <br />
      <br />
      <hr />
      <br />
    </>
  );
};

export default SectionDivider;
