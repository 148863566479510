import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";

// pages
import About from "./pages/About";
import Services from "./pages/Services";
import MeetTeam from "./pages/MeetTeam";

// components
import Footer from "./components/Footer";
import Header from "./components/Header";
import Nav from "./components/Nav";

// local
import "./assets/App.css";
import "./assets/imageSource.css";

function App() {
  return (
    <>
      <Router>
        <Nav />
        <Header />
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/meet-the-team" element={<MeetTeam />} />
        </Routes>
      </Router>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
}

export default App;

// incorporate carousel into rendering?
