// IMPORTS

// general
import * as React from "react";
import { useEffect } from "react";

// local styles
import "../assets/App.css";
import "../assets/imageSource.css";

// Material UI components
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

// Material UI icons
import PhoneIcon from "@mui/icons-material/Phone";

// Material UI Functions

// animations
import Aos from "aos";
import "aos/dist/aos.css";

//

// inline styles
const cardMediaStyle = {
  height: 350,
  width: "100%",
  objectFit: "cover",
  objectPosition: "center top",
  borderBottom: "2px solid #bda998",
};

const cardStyle = {
  bgcolor: "#f7f3ed",
  borderRadius: "10%",
  border: 3,
  borderStyle: "solid",
  borderColor: "#685B50",
};

const dividerStyle = {
  marginLeft: "-12%",
  marginRight: "-12%",
  // width: "150%",
};

const pStyle = {
  margin: "auto",
};

const buttonStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "0",
  padding: "1px",
};

export const TeamMemberCard = (props) => {
  // Hooks

  // Functions
  useEffect(() => {
    Aos.init({
      once: true,
    });
  }, []);

  return (
    <div data-aos="fade-up" data-aos-duration="500">
      <Card className="team-member-card" sx={cardStyle}>
        <img style={cardMediaStyle} src={props.photo} alt={props.name}></img>
        <CardContent>
          <h2>{props.name}</h2>
          <h4>{props.title}</h4>
          <p>
            <i>Licensed in {props.licenseYear}</i>
          </p>
          <hr style={dividerStyle} />
          <Divider sx={dividerStyle} textAlign="left">
            <p style={pStyle}>Specializes in...</p>
          </Divider>
          <p>{props.specialty}</p>
          <hr style={dividerStyle} />
          <Button
            className="contact-button"
            href={`tel:${props.phoneTag}`}
            variant="outlined"
            size="small"
            color="success"
            fullWidth
          >
            <p style={buttonStyle}>
              <span>
                <PhoneIcon />
              </span>
              <span>Call {props.name}</span>
              <span>{props.phoneTag}</span>
            </p>
          </Button>
          <Button
            className="contact-button"
            component={Link}
            href={props.socialLink}
            variant="outlined"
            size="small"
            color="success"
            target="_blank"
            fullWidth
          >
            <p style={buttonStyle}>
              <span>{props.socialIcon}</span>
              <span>Visit {props.socialLabel}</span>
              <span>{props.socialTag}</span>
            </p>
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default TeamMemberCard;
