//IMPORTS

// general
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";

// bootstrap
import "bootstrap/dist/css/bootstrap.css";

// local components
import SectionDivider from "../components/SectionDivider";
import ServiceSubCarousel from "../components/ServiceSubCarousel";

// local images

// ... carousel
import carouselColoring from "../assets/images/services-photos/carousel/carouselColoring.jpeg";
import carouselExtensions from "../assets/images/services-photos/carousel/carouselExtensions.jpeg";
import carouselSmoothing from "../assets/images/services-photos/carousel/carouselSmoothing.jpeg";
import carouselBonding from "../assets/images/services-photos/carousel/carouselBonding.jpeg";
import carouselCutStyle from "../assets/images/services-photos/carousel/carouselCutStyle.jpeg";

// ... custom coloring
import colorCorrection from "../assets/images/services-photos/hair-pics/color/colorCorrection.jpeg";
import colorGrayBlending from "../assets/images/services-photos/hair-pics/color/colorGrayBlending.jpeg";
import colorGrayCoverage from "../assets/images/services-photos/hair-pics/color/colorGrayCoverage.jpeg";
import colorVividFashion from "../assets/images/services-photos/hair-pics/color/colorVividFashion.jpeg";
import colorBlonding from "../assets/images/services-photos/hair-pics/color/colorBlonding.jpeg";

// ... extensions
import extensionsSewInWefts from "../assets/images/services-photos/hair-pics/extension/extensionsSewInWefts.jpeg";
import extensionsHiddenBead from "../assets/images/services-photos/hair-pics/extension/extensionsHiddenBead.jpeg";
import extensionsKeratin from "../assets/images/services-photos/hair-pics/extension/extensionsKeratin.jpeg";
import extensionsTapeIn from "../assets/images/services-photos/hair-pics/extension/extensionsTapeIn.jpeg";
import extensionsCombLine from "../assets/images/services-photos/hair-pics/extension/extensionsCombLine.jpeg";

// ... smoothing
import smoothingBrazilian from "../assets/images/services-photos/hair-pics/smoothing/smoothingBrazilian.jpeg";

// ... bond building
import bondingOlaplex from "../assets/images/services-photos/hair-pics/bond/bondingOlaplex.jpeg";
import bondingK18 from "../assets/images/services-photos/hair-pics/bond/bondingK18.jpeg";
import bondingLoreal from "../assets/images/services-photos/hair-pics/bond/bondingLorealMolecular.jpeg";
import bondingRedkin from "../assets/images/services-photos/hair-pics/bond/bondingRedken.jpeg";

// ... haircuts/hairstyles
import hairCutStyle1 from "../assets/images/services-photos/hair-pics/cutStyle/hairCutStyle1.jpeg";
import hairCutStyle2 from "../assets/images/services-photos/hair-pics/cutStyle/hairCutStyle2.jpeg";

// local styles
import "../assets/App.css";
import "../assets/imageSource.css";

// Bootstrap Components
import Carousel from "react-bootstrap/Carousel";

// Material UI Components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// inline styles

const accordionStyle = {
  background: "#CBC4B9",
};

const addShadow = {
  textShadow: "0px 1px 4px black",
};

const carouselImgStyle = {
  width: "100%",
  maxHeight: "700px",
  textAlign: "center",
};

// props contents
const colorServicesInfo = {
  image: [
    colorBlonding,
    colorVividFashion,
    colorGrayCoverage,
    colorGrayBlending,
    colorCorrection,
  ],

  subject: "Custom Coloring",
  subjectDescription: "Let your hair color show your true colors",
  kind: [
    "Blonding",
    "Vivid & Fashion Colors",
    "Gray Coverage",
    "Gray Blending",
    "Color Correction",
  ],
};

const hairExtensionsInfo = {
  image: [
    extensionsSewInWefts,
    extensionsHiddenBead,
    extensionsKeratin,
    extensionsTapeIn,
    extensionsCombLine,
  ],

  subject: "Hair Extensions",
  subjectDescription: "Make your hair an extension of you",
  kind: [
    "Sew-in Wefts",
    "Hidden Bead Method",
    "Keratin Fusion",
    "Tape-in Extensions",
    "CombLine Method",
  ],
  kindDescription: [
    "A gentle method of attaching the wefts of hair with small beads along the section of attachment to your natural hair ",
    "A bead-based method where beads remain hidden throughout the entire wear of your extensions under the wefts of hair, placed above and below the beaded row",
    "An effective hair extension method where the stylist attaches strands to your natural hair using keratin bonds",
    "A type of permanent hair extension consisting of a 1- to 2-inch-wide weft of hair secured at the top of the extension, with a sticky tab that is attached to the hair.",
    "The most undetectable of all hair extensions; these extensions are attached to a few strands of hair using an adhesive that matches your hair color",
  ],
};

const smoothingTreatmentsInfo = {
  image: [smoothingBrazilian],

  subject: "Smoothing Treatments",
  subjectDescription: "Tame and control your hair",
  kind: ["Brazilian Blowout"],
  kindDescription: [
    "A Brazilian Blowout transforms frizzy, damaged, unmanageable hair into smooth, healthy, easy-to-style strands for up to 12 weeks.",
  ],
};

const bondBuildingInfo = {
  image: [bondingOlaplex, bondingK18, bondingLoreal, bondingRedkin],

  subject: "Bond Building",
  subjectDescription: "Strengthen and fortify your hair",
  kind: [
    "Olaplex",
    "K18",
    "L'Oréal PROFESSIONAL Absolute Repair Molecular",
    "Redken Acidic Bonding Concentrate",
  ],
};

const hairCutStyleInfo = {
  image: [hairCutStyle2, hairCutStyle1],

  subject: "Haircutting and Hairstyling",
  subjectDescription: "Enhance your hair's natural beauty",
  kind: ["Haircutting", "Hairstyling"],
  kindDescription: [
    "The act of trimming or cutting one's hair.",
    "The use of various techniques and products to create a look that reflects your specific wants and needs.",
  ],
};

export const Services = () => {
  //Hooks
  const [imagesLoaded, setImagesLoaded] = useState(0);

  //Functions

  // Total number of images to be loaded in main carousel
  const totalImages = 5;

  const handleImageLoad = () => {
    setImagesLoaded((prev) => prev + 1);
  };

  // trigger scroll function after main carousel images render
  useEffect(() => {
    const pageTitleElement = document.getElementById("pageTitle");
    if (imagesLoaded === totalImages && pageTitleElement) {
      // console.log("images have loaded!");
      pageTitleElement.scrollIntoView({ behavior: "instant" });
    }
  }, [imagesLoaded]);

  // conditional rendering of sub-carousels
  const [expanded, setExpanded] = useState(new Set()); // to track accordion panel state(s)

  // Handle accordion toggles - used to render sub-carousels
  const handleToggle = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      const newExpanded = new Set(prevExpanded);
      if (isExpanded) {
        newExpanded.add(panel);
      } else {
        newExpanded.delete(panel);
      }
      return newExpanded;
    });
  };

  return (
    <>
      <SectionDivider />
      <h1 className="page-title">Services</h1>
      <div>
        {/* BOOTSTRAP CAROUSEL */}
        <div className="carousel-center">
          <Carousel className="carousel-styled">
            <Carousel.Item>
              <img
                alt="Custom Coloring"
                src={carouselColoring}
                style={carouselImgStyle}
                onLoad={handleImageLoad}
              ></img>
              <Carousel.Caption>
                <div className="carousel-caption" style={addShadow}>
                  <h3>{colorServicesInfo.subject}</h3>
                  <p>{colorServicesInfo.subjectDescription}</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                alt="Hair Extensions"
                src={carouselExtensions}
                style={carouselImgStyle}
                onLoad={handleImageLoad}
              ></img>
              <Carousel.Caption>
                <div className="carousel-caption" style={addShadow}>
                  <h3>{hairExtensionsInfo.subject}</h3>
                  <p>{hairExtensionsInfo.subjectDescription}</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                alt="Smoothing Treatments"
                src={carouselSmoothing}
                style={carouselImgStyle}
                onLoad={handleImageLoad}
              ></img>
              <Carousel.Caption>
                <div className="carousel-caption" style={addShadow}>
                  <h3>{smoothingTreatmentsInfo.subject}</h3>
                  <p>{smoothingTreatmentsInfo.subjectDescription}</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                alt="Bond Building Services"
                src={carouselBonding}
                style={carouselImgStyle}
                onLoad={handleImageLoad}
              ></img>
              <Carousel.Caption>
                <div className="carousel-caption" style={addShadow}>
                  <h3>{bondBuildingInfo.subject}</h3>
                  <p>{bondBuildingInfo.subjectDescription}</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                alt="Haircuts and Hairstyling"
                src={carouselCutStyle}
                style={carouselImgStyle}
                onLoad={handleImageLoad}
              ></img>
              <Carousel.Caption>
                <div className="carousel-caption" style={addShadow}>
                  <h3>{hairCutStyleInfo.subject}</h3>
                  <p>{hairCutStyleInfo.subjectDescription}</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
        <Divider color="white" />
        {/* MUI ACCORDION(S) */}
        <div>
          <Accordion onChange={handleToggle("panel1")} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {colorServicesInfo.subject}
            </AccordionSummary>
            <AccordionDetails className="accordion-split">
              {expanded.has("panel1") && (
                <>
                  {/* accordion-list */}
                  <div className="accordion-list accordion-slot">
                    {colorServicesInfo.kind.map((kind, index) => (
                      <h4 key={index}>- {kind}</h4>
                    ))}
                  </div>
                  <ServiceSubCarousel
                    image={colorServicesInfo.image}
                    kind={colorServicesInfo.kind}
                  />
                </>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion onChange={handleToggle("panel2")} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              {hairExtensionsInfo.subject}
            </AccordionSummary>
            <AccordionDetails className="accordion-split">
              {expanded.has("panel2") && (
                <>
                  {/* accordion-list */}
                  <div className="accordion-list accordion-slot">
                    {hairExtensionsInfo.kind.map((kind, index) => (
                      <h4 key={index}>- {kind}</h4>
                    ))}
                  </div>
                  <ServiceSubCarousel
                    image={hairExtensionsInfo.image}
                    kind={hairExtensionsInfo.kind}
                  />
                </>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion onChange={handleToggle("panel3")} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              {smoothingTreatmentsInfo.subject}
            </AccordionSummary>
            <AccordionDetails className="accordion-split">
              {expanded.has("panel3") && (
                <>
                  {/* accordion-list */}
                  <div className="accordion-list accordion-slot">
                    {smoothingTreatmentsInfo.kind.map((kind, index) => (
                      <h4 key={index}>- {kind}</h4>
                    ))}
                  </div>
                  <ServiceSubCarousel
                    image={smoothingTreatmentsInfo.image}
                    kind={smoothingTreatmentsInfo.kind}
                  />
                </>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion onChange={handleToggle("panel4")} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4-content"
              id="panel4-header"
            >
              {bondBuildingInfo.subject}
            </AccordionSummary>
            <AccordionDetails className="accordion-split">
              {expanded.has("panel4") && (
                <>
                  {/* accordion-list */}
                  <div className="accordion-list accordion-slot">
                    {bondBuildingInfo.kind.map((kind, index) => (
                      <h4 key={index}>- {kind}</h4>
                    ))}
                  </div>
                  <ServiceSubCarousel
                    image={bondBuildingInfo.image}
                    kind={bondBuildingInfo.kind}
                  />
                </>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion onChange={handleToggle("panel5")} sx={accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5-content"
              id="panel5-header"
            >
              {hairCutStyleInfo.subject}
            </AccordionSummary>
            <AccordionDetails className="accordion-split">
              {expanded.has("panel5") && (
                <>
                  {/* accordion-list */}
                  <div className="accordion-list accordion-slot">
                    {hairCutStyleInfo.kind.map((kind, index) => (
                      <h4 key={index}>- {kind}</h4>
                    ))}
                  </div>
                  <ServiceSubCarousel
                    image={hairCutStyleInfo.image}
                    kind={hairCutStyleInfo.kind}
                  />
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default Services;
